import Router from "next/router";
import {
  deleteChatLog,
  deleteQueryLog,
  getChatLogById,
  getChatLogs,
  getQueryLogById,
  getQueryLogs,
  updateChatLogById,
  updateQueryLogById,
} from "@/axios";
import { toastsBar } from "@/components/shared/snackBar/useToast";
import { routesURL } from "@/components/urls";
import { noop } from "@/components/utility";
import { AI_MODELS, CHAT_MODES } from "./querySlice";
export const historySlice = (set, get) => ({
  queryHistory: [],
  chatHistory: [],
  standByQueryId: null,
  standByChatId: null,
  loadingQueryId: false,
  loadingChatId: false,
  setLoadingQueryId: (val) => set({ loadingQueryId: val }),
  setLoadingChatId: (val) => set({ loadingChatId: val }),
  setQueryHistory: (val) => {
    if (typeof val === "function") {
      val(get().queryHistory, (val) => set({ queryHistory: val }));
    } else {
      set({ queryHistory: [val, ...get().queryHistory] });
    }
  },
  setChatHistory: (val) => {
    if (typeof val === "function") {
      val(get().chatHistory, (val) => set({ chatHistory: val }));
    } else {
      set({ chatHistory: [val, ...get().chatHistory] });
    }
  },
  setStandByQueryId: (val) => set({ standByQueryId: val }),
  setStandByChatId: (val) => set({ standByChatId: val }),
  fetchAllQueryLogs: async () => {
    getQueryLogs()
      .then((res) => {
        const { data } = res;
        get().setStandByQueryId(data.data.at(-1));
        get().setQueryHistory((prev, set) => {
          set([...data.data.slice(0, data.data.length - 1)].reverse());
        });
      })
      .catch((error) => {
        toastsBar("Could not fetch query logs.", "error");
        // eslint-disable-next-line
        console.log(error);
      });
  },
  fetchQueryLog: async (
    id,
    config,
    overWritePrevQuery = true,
    callback = noop
  ) => {
    get().setLoadingQueryId(true);
    try {
      const res = await getQueryLogById(id, config);

      const { title, description, cells } = res.data.data;
      cells.forEach((cell) => {
        cell.aiModel = AI_MODELS.find(
          (model) => model.value === cell.aiModel.value
        );
      });
      overWritePrevQuery &&
        get().initializeQueryObject({
          title,
          description,
          cells,
        });

      get().setLoadingQueryId(false);
      callback(res.data.data);
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        toastsBar("Could not find query logs for the " + id, "error");
        Router.push(routesURL.dasboard);
        get().setLoadingQueryId(false);
      }
    }
  },
  updateQueryLog: async ({ id, shouldFetchAllLogs = true }) => {
    const { title, description, cells } = get();
    const cleanedData = cells.map((cell) => {
      return {
        ...cell,
        data: { column_names: [], results: [] },
        resErrors: "",
        resDuration: null,
        page: 1,
        totalPages: 1,
        metrics: {
          query_time: 0,
          total_columns: 0,
          total_rows: 0,
          size: 0,
        },
      };
    });
    const data = {
      title,
      description,
      cells: cleanedData,
    };
    await updateQueryLogById(id, data);
    shouldFetchAllLogs && get().fetchAllQueryLogs();
  },
  assignNewQueryLog: () => {
    const currentId = get().standByQueryId.uuid;
    get()
      .updateQueryLog({ id: currentId })
      .catch((error) => {
        console.error("Failed to update query log:", error);
      });
    return currentId;
  },
  deleteQueryLog: async (id) => {
    await deleteQueryLog(id);
    if (id === Router.query.uuid) {
      Router.push(routesURL.dasboard);
    }
    get().fetchAllQueryLogs();
  },
  fetchAllChatLogs: async () => {
    getChatLogs()
      .then((res) => {
        const { data } = res;
        get().setStandByChatId(data.data.at(-1));
        get().setChatHistory((prev, set) => {
          set([...data.data.slice(0, data.data.length - 1)].reverse());
        });
      })
      .catch((error) => {
        toastsBar("Could not fetch query logs.", "error");
        // eslint-disable-next-line
        console.log(error);
      });
  },
  fetchChatLog: async (
    id,
    config,
    overWritePrevChat = true,
    callback = noop
  ) => {
    get().setLoadingChatId(true);
    try {
      const res = await getChatLogById(id, config);
      const {
        setChat,
        setSelectedDbSnapShot,
        setCurrentDb,
        syncedDbOptions,
        setChatMode,
        setAIModel,
      } = get();
      overWritePrevChat && setChat(res.data.data.conversation.messages);
      setCurrentDb(
        syncedDbOptions.find(
          (item) => Number(item.id) === Number(res.data.data.data_source_id)
        )
      );

      setSelectedDbSnapShot(res.data.data.data_source_id);
      setChatMode(CHAT_MODES.find((mode) => mode.value === res.data.data.mode));
      setAIModel(
        AI_MODELS.find((model) => model.value === res.data.data.ai_model)
      );
      get().setLoadingChatId(false);
      callback(res.data.data);
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        toastsBar("Could not find chat logs for the " + id, "error");

        Router.push(routesURL.dasboard);
      }
    }
  },
  assignNewChatLog: () => {
    const currentId = get().standByChatId.uuid;
    get()
      .updateChatLog(currentId)
      .catch((error) => {
        console.error("Failed to update query log:", error);
      });
    return currentId;
  },
  updateChatLog: async (id) => {
    const messages = window?.structuredClone(get().chat).map((c) => {
      if (c.type === "ai")
        c.slots.forEach((slot) => {
          if (slot.type === "sqlQuery") {
            slot.isParentFlow = false;
            slot.errors = null;
            slot.state = {
              ...slot.state,
              data: { column_names: [], results: [] },
              activeColumns: [],
              duration: 0,
              totalPages: 1,
              page: 1,
              loading: false,
              text: slot.state.text,
              metrics: {
                query_time: 0,
                size: 0,
                total_columns: 0,
                total_rows: 0,
              },
            };
          }
        });
      return c;
    });
    const data = {
      data_source_id: Number(get().currentDb.id),
      mode: get().chatMode?.value,
      ai_model: get().aiModel?.value,
      conversation: { messages: messages },
    };
    try {
      await updateChatLogById(id, data);
      get().fetchAllChatLogs();
    } catch (error) {
      toastsBar("Could not update the chat log", "error");
      // eslint-disable-next-line
      console.log(error);
    }
  },
  updateChatLogOnCondition: (
    id,
    { shouldUpdate = false, condition = false }
  ) => {
    if (shouldUpdate || condition) {
      get().updateChatLog(id);
    }
  },
  deleteChatLog: async (id) => {
    await deleteChatLog(id);
    if (id === Router.query.uuid) {
      Router.push(routesURL.dasboard);
    }
    get().fetchAllChatLogs();
  },
});
